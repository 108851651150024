<template>
  <div>
    <v-dialog v-model="dialogAguarde" persistent width="10vw">
      <v-card>
        <v-card-title>
          <div class="d-flex justify-space-around align-center">
            <span>Aguarde...</span>
            <v-progress-circular
              color="blue"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-title>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogTratamento" persistent width="10vw">
      <div
        class="pa-3 d-flex justify-space-between align-center"
        style="background-color: white"
      >
        <span>Salvando...</span
        ><v-progress-circular
          indeterminate
          :size="18"
          color="cyan darken-4"
        ></v-progress-circular>
      </div>
    </v-dialog>
    <v-dialog v-model="dialogOS" width="88vw">
      <v-card>
        <v-card-title>OS: {{ osEditada.OS }}</v-card-title>
        <v-card-subtitle>Visualização de OS</v-card-subtitle>
        <v-card-text>
          <div class="dadosOS d-flex justify-start align-center">
            <div class="d-flex flex-column justify-start align-start pa-2">
              <span class="ml-1 text-caption font-weight-black">Filial</span>
              <v-text-field
                :value="dadosVisualizacaoOS.FILIAL"
                label="Filial"
                dense
                outlined
                solo
                readonly
                hide-details
                style="width: 8vw !important;"
              ></v-text-field>
            </div>
            <div class="d-flex flex-column justify-start align-start pa-2">
              <span class="ml-1 text-caption font-weight-black">Vendedor</span>
              <v-text-field
                :value="dadosVisualizacaoOS.VENDEDOR_RESPONSAVEL"
                label="Vendedor"
                dense
                outlined
                solo
                readonly
                hide-details
                style="width: 20vw !important;"
              ></v-text-field>
            </div>
            <div class="d-flex flex-column justify-start align-start pa-2">
              <span class="ml-1 text-caption font-weight-black">Canal</span>
              <v-text-field
                :value="dadosVisualizacaoOS.CANAL"
                label="Canal"
                dense
                outlined
                solo
                readonly
                hide-details
                style="width: 12vw !important;"
              ></v-text-field>
            </div>
            <div class="d-flex flex-column justify-start align-start pa-2">
              <span class="ml-1 text-caption font-weight-black"
                >Médico / Indicador</span
              >
              <v-text-field
                :value="dadosVisualizacaoOS.MEDICO_INDICADOR"
                label="Médico"
                dense
                outlined
                solo
                readonly
                hide-details
                style="width: 16vw !important;"
              ></v-text-field>
            </div>
            <div class="d-flex flex-column justify-start align-start pa-2">
              <span class="ml-1 text-caption font-weight-black"
                >Tipo de OS</span
              >
              <v-text-field
                :value="dadosVisualizacaoOS.TIPO_OS"
                label="Tipo de OS"
                dense
                outlined
                solo
                readonly
                hide-details
                style="width: 8vw !important;"
              ></v-text-field>
            </div>
            <div class="d-flex flex-column justify-start align-start pa-2">
              <span class="ml-1 text-caption font-weight-black">Cliente</span>
              <v-text-field
                :value="dadosVisualizacaoOS.CLIENTE"
                label="Cliente"
                dense
                outlined
                solo
                readonly
                hide-details
                style="width: 16vw !important;"
              ></v-text-field>
            </div>
          </div>
          <br />
          <div
            class="dadosOS d-flex justify-start align-center"
            style="margin-top: -20px;"
          >
            <div class="d-flex flex-column justify-start align-start pa-2">
              <span class="ml-1 text-caption font-weight-black">Status OS</span>
              <v-text-field
                :value="dadosVisualizacaoOS.STATUS_OS"
                label="Status OS"
                dense
                outlined
                solo
                readonly
                hide-details
                style="width: 12vw !important;"
              ></v-text-field>
            </div>
            <div class="d-flex flex-column justify-start align-start pa-2">
              <span class="ml-1 text-caption font-weight-black">Técnico</span>
              <v-text-field
                :value="dadosVisualizacaoOS.TECNICO"
                label="Técnico"
                dense
                outlined
                solo
                readonly
                hide-details
                style="width: 18vw !important;"
              ></v-text-field>
            </div>
            <div class="d-flex flex-column justify-start align-start pa-2">
              <span class="ml-1 text-caption font-weight-black"
                >Cond. Pgto</span
              >
              <v-text-field
                :value="dadosVisualizacaoOS.COND_PGTO"
                label="Cond. Pgto"
                dense
                outlined
                solo
                readonly
                hide-details
                style="width: 15vw !important;"
              ></v-text-field>
            </div>
          </div>
          <v-divider></v-divider>
          <br />
          <div>
            <span class="ml-1 text-h5 font-weight-black">Aparelho</span>
            <v-data-table
              dense
              :items="itemsAparelho"
              :headers="headerAparelhos"
              hide-default-footer
              no-data-text="sem aparelho no momento"
            ></v-data-table>
          </div>
          <br />
          <v-divider></v-divider>
          <div>
            <span class="ml-1 text-h5 font-weight-black">Apontamentos</span>
            <v-data-table
              dense
              :items="itemsApontamento"
              :headers="headerApontamento"
              hide-default-footer
              no-data-text="sem apontamento(s) no momento"
            ></v-data-table>
            <div class="d-flex justify-end align-center">
              <span class="mr-3 text-h5">Total</span>
              <span class="text-h4">{{ reaisApontamento }}</span>
              <span class="text-h7" style="margin-top: 12px;">{{
                `,${centavosApontamento}`
              }}</span>
            </div>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn x-small class="error" @click="dialogOS = !dialogOS"
            >Fechar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogStatus" persistent width="40vw">
      <v-card>
        <v-card-title>Troca de Status de OS</v-card-title>
        <v-card-subtitle>OS: XXXXXXXXX</v-card-subtitle>
        <v-card-text>
          <div class="d-flex justify-center align-center">
            <v-autocomplete
              v-model="codStatus"
              dense
              hide-details
              :items="itemsStatus"
              :loading="loadingStatus"
              item-text="ZB2_DESCRI"
              item-value="ZB2_COD"
            ></v-autocomplete>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            small
            class="error"
            @click="
              dialogStatus = !dialogStatus;
              codStatus = null;
            "
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn small class="primary" @click="salvaStatus">Salvar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card>
      <v-card-title>
        Central OS
        <v-spacer></v-spacer>
        <v-text-field
          v-model="dtIncial"
          append-icon="mdi-calendar"
          label="Data Inicial"
          single-line
          hide-details
          v-mask="'##/##/####'"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="dtFinal"
          append-icon="mdi-calendar"
          label="Data Final"
          single-line
          hide-details
          v-mask="'##/##/####'"
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="numOS"
          append-icon="mdi-ear-hearing-loop"
          label="OS"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-select
          v-model="statusEscolhido"
          :items="statusOS"
          dense
          hide-details
          label="Status"
          item-text="text"
          item-value="value"
          style="margin-top: 21px;"
        ></v-select>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Pesquisar"
          single-line
          hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn class="primary" small @click="buscaDados">Buscar</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="orange darken-2" dark small @click="setaTitle"
          >Fila Atendimento</v-btn
        >
      </v-card-title>
      <v-data-table
        dense
        fixed-header
        v-model="selectLeads"
        :headers="headerTabOs"
        :items="itensTabOS"
        item-key="OS"
        :items-per-page="itensTabOS.length"
        :search="search"
        hide-default-footer
        :loading="loadingOS"
        height="55vh"
        class="tbCamp"
        no-data-text="sem dados encontrados"
        @click:row="buscaDetalhes"
        ><template v-slot:[`item.action`]="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                small
                @click="buscaStatus(item)"
                v-bind="attrs"
                v-on="on"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Trocar Status</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                class="mr-2"
                small
                @click="visualizaOS(item)"
                v-bind="attrs"
                v-on="on"
                >mdi-eye</v-icon
              >
            </template>
            <span>Trocar Status</span>
          </v-tooltip>
        </template></v-data-table
      >
      <v-divider></v-divider>
      <v-data-table
        dense
        fixed-header
        :headers="headerBipiOS"
        :items="itensBipiOS"
        :items-per-page="itensBipiOS.length"
        hide-default-footer
        :loading="loadingItens"
        height="25vh"
        class="mt-2"
      >
        <template v-slot:top>
          <div class="d-flex justify-center align-center text-h6">
            Detalhes Ordem de serviço: {{ osDetalhada }}
          </div>
        </template>
        <template v-slot:[`item.STATUS`]="{ item }">
          <v-chip
            color="green darken-2"
            dark
            x-small
            v-if="item.STATUS === 'EM PROCESSO'"
            >{{ item.STATUS }}</v-chip
          >
          <v-chip color="red darken-2" dark x-small v-else>{{
            item.STATUS
          }}</v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import { floatToMoney } from "@/utils/stringParse";
import axios from "axios";
import { mapState } from "vuex";
export default {
  name: "HomeCentralOS",
  computed: {
    ...mapState(["user"]),
  },
  data: () => ({
    statusEscolhido: null,
    statusOS: [],
    dialogAguarde: false,
    headerApontamento: [],
    itemsApontamento: [],
    itemsAparelho: [],
    headerAparelhos: [],
    dadosVisualizacaoOS: [],
    dialogOS: false,
    dialogTratamento: false,
    codStatus: null,
    dialogStatus: false,
    loadingStatus: true,
    itemsStatus: [],
    dtIncial: null,
    dtFinal: null,
    numOS: null,
    search: null,
    headerTabOs: [],
    itensTabOS: [],
    loadingOS: false,
    headerBipiOS: [],
    itensBipiOS: [],
    loadingItens: false,
    osDetalhada: null,
    selectLeads: [],
    osEditada: [],
    reaisApontamento: null,
    centavosApontamento: null,
  }),
  methods: {
    async visualizaOS(item) {
      this.dialogAguarde = true;
      this.itemsAparelho = [];
      this.headerAparelhos = [];
      this.itemsApontamento = [];
      this.headerApontamento = [];
      this.reaisApontamento = null;
      this.centavosApontamento = null;
      this.osEditada = item;
      const dados = {
        FIELDS: `ZF1_MSFIL+'-'+ZF1_NOMFIL FILIAL,
                  ZF1_RESP+'-'+SA3.A3_NOME VENDEDOR_RESPONSAVEL,
                  ZF1_ZCANAL+'-'+ZMC_DESCRI CANAL,
                  ZF1_ASSESS+'-'+SA3M.A3_NOME MEDICO_INDICADOR,
                  ZF1_TIPO+'-'+ZF6_DESCRI TIPO_OS,
                  ZF1_CODCLI+'-'+A1_NOME CLIENTE,
                  ZF1_STATUS+'-'+ZF7_DESCRI STATUS_OS,
                  ZF1_CODTEC+'-'+ZZK_NOME TECNICO,
                  ZF1_CONDPG+'-'+E4_DESCRI COND_PGTO`,
        INNER: `INNER JOIN SA3010 SA3 WITH(NOLOCK) ON 
                            SA3.D_E_L_E_T_=' ' AND 
                            A3_COD=ZF1_RESP AND 
                            A3_MSBLQL='2'

                LEFT JOIN ZMC010 ZMC WITH(NOLOCK) ON 
                            ZMC.D_E_L_E_T_=' ' AND 
                            ZMC_CODIGO=ZF1_ZCANAL

                LEFT JOIN SA3010 SA3M WITH(NOLOCK) ON 
                            SA3M.D_E_L_E_T_=' ' AND
                            SA3M.A3_COD=ZF1_ASSESS AND 
                            SA3M.A3_MSBLQL='2'

                LEFT JOIN ZF6010 ZF6 WITH(NOLOCK) ON 
                            ZF6.D_E_L_E_T_=' ' AND 
                            ZF6_COD=ZF1_TIPO

                LEFT JOIN SA1010 SA1 WITH(NOLOCK) ON 
                            SA1.D_E_L_E_T_=' ' AND 
                            A1_COD=ZF1_CODCLI AND 
                            A1_LOJA=ZF1_LOJA

                LEFT JOIN ZF7010 ZF7 WITH(NOLOCK) ON 
                            ZF7.D_E_L_E_T_=' ' AND 
                            ZF7_COD=ZF1_STATUS

                LEFT JOIN ZZK010 ZZK WITH(NOLOCK) ON 
                            ZZK.D_E_L_E_T_=' ' AND 
                            ZZK_COD=ZF1_CODTEC

                LEFT JOIN SE4010 SE4 WITH(NOLOCK) ON 
                            SE4.D_E_L_E_T_=' ' AND 
                            E4_CODIGO=ZF1_CONDPG

                LEFT JOIN ZF2010 ZF2 WITH(NOLOCK) ON 
                            ZF2.D_E_L_E_T_=' ' AND 
                            ZF2_ID=ZF1_ID `,
        WHERE: `ZF1_ID='${item.OS}'`,
        TABELA: "ZF1",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      this.dadosVisualizacaoOS = await axios.post(url, dados);

      this.dadosVisualizacaoOS = { ...this.dadosVisualizacaoOS.data[0] };

      dados.FIELDS = `ZF2_CODPRO,ZF2_DESPRO,ZF2_NUMSER,ZF2_LOCAL,ZF2_VUNIT,ZF2_GARANT,ZF2_REPARO,CONVERT(VARCHAR(MAX),ZF2_OBSERV) ZF2_OBSERV,CONVERT(VARCHAR(MAX),ZF2_ACESSO) ZF2_ACESSO`;
      dados.INNER = ``;
      dados.WHERE = `ZF2_ID='${item.OS}'`;
      dados.TABELA = "ZF2";

      const aparelho = await axios.post(url, dados);

      // this.headerAparelhos = Object.keys(aparelho.data[0]).map(e=> {
      //   return {
      //     text: e,
      //     value: e
      //   }
      // })

      this.headerAparelhos = [
        { text: "Produto", value: "ZF2_CODPRO" },
        { text: "Descriçâo", value: "ZF2_DESPRO" },
        { text: "Número de Sèrie", value: "ZF2_NUMSER", align: "center" },
        { text: "Valor", value: "ZF2_VUNIT", align: "end" },
        { text: "Garantia", value: "ZF2_GARANT", align: "center" },
        { text: "Reparo", value: "ZF2_REPARO", align: "center" },
        { text: "Observaçâo", value: "ZF2_OBSERV" },
        { text: "Acessórios", value: "ZF2_ACESSO" },
      ];

      aparelho.data.map(e => (e.ZF2_VUNIT = floatToMoney(e.ZF2_VUNIT)));

      this.itemsAparelho = aparelho.data;

      dados.FIELDS =
        "ZF3_CODSER,ZF3_CODPRO,ZF3_DESPRO,ZF3_NUMSER,ZF3_QUANT,ZF3_PRCLIS,ZF3_VUNIT,ZF3_VTOTAL,ZF3_LADORM,ZF3_DOCREM,ZF3_DOCRET,ZF3_LOCAL";
      dados.WHERE = `ZF3_ID='${item.OS}'`;
      dados.TABELA = "ZF3";

      const apontamentos = await axios.post(url, dados);

      let totalApontamento = 0;

      apontamentos.data.map(e => {
        e.ZF3_PRCLIS = floatToMoney(e.ZF3_PRCLIS);
        e.ZF3_VUNIT = floatToMoney(e.ZF3_VUNIT);
        totalApontamento += e.ZF3_VTOTAL;
        e.ZF3_VTOTAL = floatToMoney(e.ZF3_VTOTAL);
      });

      this.headerApontamento = [
        { text: "Cód Serviço", value: "ZF3_CODSER" },
        { text: "Cód Produto", value: "ZF3_CODPRO" },
        { text: "Descrição", value: "ZF3_DESPRO" },
        { text: "Num. Série", value: "ZF3_NUMSER", align: "center" },
        { text: "Local", value: "ZF3_LOCAL", align: "center" },
        { text: "Quantidade", value: "ZF3_QUANT", align: "end" },
        { text: "Prc. Lista", value: "ZF3_PRCLIS", align: "end" },
        { text: "Prc. Unitário", value: "ZF3_VUNIT", align: "end" },
        { text: "Total", value: "ZF3_VTOTAL", align: "end" },
        { text: "Lado", value: "ZF3_LADORM", align: "center" },
        { text: "Doc. Remessa", value: "ZF3_DOCREM", align: "center" },
        { text: "Doc. Retorno", value: "ZF3_DOCRET", align: "center" },
      ];

      this.itemsApontamento = apontamentos.data;

      let quebra = floatToMoney(totalApontamento).split(",");

      this.reaisApontamento = quebra[0];
      this.centavosApontamento = quebra[1];

      this.dialogAguarde = false;

      this.dialogOS = true;
    },
    async salvaStatus() {
      if (this.codStatus === this.osEditada.ZB2_COD) {
        this.$toast.error("Status escolhido igual ao atual!", {
          position: "bottom-center",
          timeout: 5000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: false,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
        return;
      }

      const dados = {
        STATUS: this.codStatus,
        OS: this.osEditada.OS,
        USERPROT: this.user.usr_protheus,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/statusOS`;

      this.dialogTratamento = true;
      await axios
        .put(url, dados)
        .then(res => {
          if (res.data.Status === 200) {
            this.$toast.success(res.data.Message, {
              position: "bottom-center",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
            const indexEditado = this.itensTabOS.findIndex(
              e => e.OS === this.osEditada.OS,
            );

            const status = this.itemsStatus.filter(
              e => e.ZB2_COD === this.codStatus,
            );

            if (indexEditado > -1) {
              Object.assign(this.itensTabOS[indexEditado], {
                STATUS_ATUAL: status[0].ZB2_DESCRI,
              });
            }
            this.buscaDetalhes(this.itensTabOS[indexEditado]);
            this.dialogStatus = false;
            this.codStatus = null;
          } else {
            this.$toast.error(res.data.Message, {
              position: "bottom-center",
              timeout: 5000,
              closeOnClick: true,
              pauseOnFocusLoss: true,
              pauseOnHover: true,
              draggable: true,
              draggablePercent: 0.6,
              showCloseButtonOnHover: false,
              hideProgressBar: false,
              closeButton: "button",
              icon: true,
              rtl: false,
            });
          }
        })
        .catch(err => {
          console.log(err);
        });

      this.dialogTratamento = false;
    },
    async buscaStatus(item) {
      this.itemsStatus = [];
      this.loadingStatus = true;
      this.dialogStatus = true;
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: "",
        WHERE: ``,
        TABELA: "ZB2",
      };
      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const status = await axios.post(url, dados);

      this.itemsStatus = status.data.map(e => {
        return {
          text: e.ZB2_DESCRI.trim(),
          value: e.ZB2_COD,
          ...e,
        };
      });

      this.loadingStatus = false;

      this.osEditada = item;
    },
    async buscaDados() {
      this.headerTabOs = [];

      this.itensTabOS = [];

      this.loadingOS = true;

      let dI;
      let dF;

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS:
          "ZM0_NOME FILIAL,ZF1_ID OS,ZF1_EMISSA EMISSAO,ZZK_NOME TECNICO,ZB2_DESCRI STATUS_ATUAL,A1_COD COD_CLIENTE,A1_NOME NOME,ZF1_DTINTA PREV_ATENDIMENTO,ZB2_COD",
        WHERE: ``,
        INNER: `INNER JOIN ZM0010 ZM0 WITH(NOLOCK) ON 
                            ZM0.D_E_L_E_T_=' '
                            AND ZM0_CODFIL=ZF1_MSFIL

                INNER JOIN SA1010 SA1 WITH(NOLOCK) ON 
                            SA1.D_E_L_E_T_=' '
                            AND A1_COD=ZF1_CODCLI

                INNER JOIN ZZK010 ZZK WITH(NOLOCK) ON
                            ZZK.D_E_L_E_T_=' '
                            AND ZZK_COD=ZF1_CODTEC

                LEFT JOIN ZB3010 ZB3 WITH(NOLOCK) ON 
                            ZB3.D_E_L_E_T_=' '
                            AND ZB3_CODOS=ZF1_ID 
                            AND ZB3_SITUAC='P'

                LEFT JOIN ZB2010 ZB2 WITH(NOLOCK) ON
                            ZB2.D_E_L_E_T_=' '
                            AND ZB2_COD=ZB3_STATUS `,
        TABELA: "ZF1",
      };

      if (this.dtIncial && this.dtFinal) {
        dI = this.dtIncial.split("/");
        dF = this.dtFinal.split("/");

        dados.WHERE = `ZF1_TIPO='01' AND ZF1_EMISSA BETWEEN '${dI[2]}${dI[1]}${dI[0]}' AND '${dF[2]}${dF[1]}${dF[0]}'`;
      }

      if (this.statusEscolhido) {
        dados.WHERE =
          dados.WHERE.length > 0
            ? dados.WHERE + ` AND ZB3_STATUS='${this.statusEscolhido}'`
            : `ZB3_STATUS='${this.statusEscolhido}'`;
      }

      if (this.numOS)
        dados.WHERE =
          dados.WHERE.length > 0
            ? dados.WHERE + ` AND ZF1_ID='${this.numOS}'`
            : `ZF1_ID='${this.numOS}'`;

      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const OS = await axios.post(url, dados);

      if (OS.data.length > 0) {
        OS.data.map(e => {
          e.EMISSAO = `${e.EMISSAO.substr(6, 2)}/${e.EMISSAO.substr(
            4,
            2,
          )}/${e.EMISSAO.substr(0, 4)}`;
          e.PREV_ATENDIMENTO = `${e.PREV_ATENDIMENTO.substr(
            6,
            2,
          )}/${e.PREV_ATENDIMENTO.substr(4, 2)}/${e.PREV_ATENDIMENTO.substr(
            0,
            4,
          )}`;
        });

        this.headerTabOs = Object.keys(OS.data[0]).map(e => {
          return {
            text: e,
            value: e,
          };
        });

        this.headerTabOs = this.headerTabOs.filter(e => e.text !== "ZB2_COD");

        this.headerTabOs.push({
          text: "Ações",
          value: "action",
        });
        this.itensTabOS = OS.data;
        this.buscaDetalhes(OS.data[0]);
      }

      this.loadingOS = false;
    },
    async buscaDetalhes(item) {
      this.dialogAguarde = true;
      this.selectLeads = [item];

      this.headerBipiOS = [];

      this.itensBipiOS = [];

      this.loadingItens = true;

      this.osDetalhada = item.OS;

      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        FIELDS: `USR_NOME USUARIO,
                  ZB2_DESCRI STEP,
                  ZB3_DTINI INICIO,
                  ZB3_HRINI HORA_INICIO,
                  CASE WHEN ZB3_SITUAC = 'P' THEN 'EM PROCESSO' ELSE 'FINALIZADO' END STATUS,
                  ZB3_DTFIM FINAL,
                  ZB3_HRFIM HORA_FINAL,
                  CASE WHEN LTRIM(ZB3_DTFIM) <> ''  THEN DATEDIFF(DAY, CONVERT(VARCHAR(8),ZB3_DTINI,112), CONVERT(VARCHAR(8),ZB3_DTFIM,112)) ELSE 0 END DIAS_CORRIDOS `,
        INNER: `INNER JOIN SYS_USR USR WITH(NOLOCK) ON
                            USR.D_E_L_E_T_=' '
                            AND USR_ID=ZB3_USRCOD

                INNER JOIN ZB2010 ZB2 WITH(NOLOCK) ON 
                            ZB2.D_E_L_E_T_=' '
                            AND ZB2_COD=ZB3_STATUS `,
        WHERE: `ZB3.D_E_L_E_T_=' ' AND ZB3_CODOS='${this.osDetalhada}' ORDER BY ZB3.R_E_C_N_O_ DESC`,
        TABELA: "ZB3",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const detalhes = await axios.post(url, dados);

      if (detalhes.data.length > 0) {
        detalhes.data.map(e => {
          e.INICIO = `${e.INICIO.substr(6, 2)}/${e.INICIO.substr(
            4,
            2,
          )}/${e.INICIO.substr(0, 4)}`;
          e.FINAL = `${e.FINAL.substr(6, 2)}/${e.FINAL.substr(
            4,
            2,
          )}/${e.FINAL.substr(0, 4)}`;
        });
        this.headerBipiOS = Object.keys(detalhes.data[0]).map(e => {
          return {
            text: e,
            value: e,
            width: "50px",
            align: "center",
          };
        });
        this.itensBipiOS = detalhes.data;
      }

      this.loadingItens = false;
      this.dialogAguarde = false;
    },
    setaTitle() {
      this.$store.commit("SetTabs", {
        name: `Fila Atendimento`,
        content: `FilaAtendimento`,
      });
      // this.$store.commit("SetTabActive", this.tabs.length - 1);
      this.$store.commit("SetTitle", "FilaAtendimento");

      //this.$router.push({ name: `${item.name}` }).catch(() => {});
    },
    async buscaStatusOS() {
      this.statusOS = [];
      const dados = {
        EMPRESA: "01",
        FILIAL: "0101",
        TABELA: "ZB2",
        FIELDS: "ZB2_COD,ZB2_DESCRI",
        WHERE: "1=1 ORDER BY ZB2_COD",
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/knex/consultas`;

      const status = await axios.post(url, dados);

      this.statusOS = status.data.map(e => {
        return {
          text: e.ZB2_DESCRI.trim(),
          value: e.ZB2_COD,
          ...e,
        };
      });

      this.statusOS.push({
        text: "Sem filtro",
        value: null,
      });
    },
  },
  mounted() {
    this.buscaStatusOS();
  },
};
</script>

<style>
.theme--light.v-data-table.tbCamp tbody tr.v-data-table__selected {
  background: #0fcbe1 !important;
}

.theme--light.v-data-table.tbCamp tbody tr.v-data-table__selected {
  background: #0fcbe1 !important;
}

.v-data-table.tbCamp tbody tr :hover {
  cursor: pointer;
}
</style>
